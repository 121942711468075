@import url('https://fonts.googleapis.com/css?family=Pacifico');
@import url('https://fonts.googleapis.com/css?family=Rammetto+One');
@import url('https://fonts.googleapis.com/css?family=VT323');
@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import url('https://fonts.googleapis.com/css?family=Inconsolata');
@import url('https://fonts.googleapis.com/css?family=Acme');
@import url('https://fonts.googleapis.com/css?family=Orbitron');

@media print {
  * {
    margin: 0 !important;
    padding: 0 !important;
  }
  .no-print { display: none; }
  .printable { display: inherit; }
}

@page {
  size: 210mm 297mm;
  margin: 0;
  padding: 0;
}

canvas {
  image-rendering: crisp-edges;
}

.App {
  text-align: left;
}

.brand-logo {
  margin-left: 10px;
}

.App-body {
  width: 100%;
}

#canvas-container {
  width: 50%;
  float: left;
  margin: auto;
}

.canvas-container {
  position: absolute !important;
}

#builder-container {
  margin-top: 0px;
  width: 50%;
  overflow: auto;
  text-align: right;
}
.palette-sidebar {
  cursor: pointer;
  width: 64px !important;
  position: absolute;
  z-index: 10;
}

#label-selector-div {
  width: 50%;
  float: right;
}

#label-selector {
  margin: 0px auto;
  margin-top: 10px;
}

#edit-all {
  margin-bottom: 10px;
  margin-left: 34px;
}
#edit-one {
  margin-bottom: 10px;
}
.inputs-paper{
  margin: 0px auto;
  margin-top: 15px;
  width: 60%;
}
.input-multiselection {
  min-width: 250px;
}

#text-controls {
}

#fabric-canvas {
  border: 1px solid rgb(183, 183, 183);
}

#grid-canvas {
  position: absolute;
}

#canvas-elements {
  margin-right: 40px;
  margin-top: 20px;
  margin-left: 250px;
}

.add-button {
  margin-right: 10px;
}
.remove-button {
  margin-right: 10px;
}
.copy-button {
  margin-right: 10px;
}
.paste-button {
  margin-right: 10px;
}

#new-model {
  margin-top: 10px;
}

#print-table {
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  top: 0;
  left: 0;
  width: 210mm;
  height: 297mm;
}

#print-cell {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  text-align: center;
}

#print-margin {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
#print-margin-internal {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}

.canvas-img {
  vertical-align: bottom;
}

table.print-preview {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
td.margin {
  border-spacing: 0;
  padding: 0;
  text-align: center;
}
td.selectable {
  border-spacing: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.photoshop-picker {
  margin-top: 40px;
}

[type="checkbox"]+label {
  padding-left: 23px;
  line-height: 20px;
}

.login-form {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.bad-login {
  margin-top: 55px;
}

.marginleft12 {
  margin-left: 12px;
}

.margintop0 {
  margin-top: 0px;
}

.placeholder-row {
  margin-bottom: 10px;
}

.no-margin-row {
  margin-bottom: 0px !important;
}

.centered-hor {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.centered {
  position: absolute;
  text-align: center;
  font-size: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.register-form {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.good-register {
  margin-top: 70px;
}
.bad-register {
  margin-top: 70px;
}

.centered-preloader {
  position: absolute;
  left: 50%;
}
.logo {
  padding-top: 4px;
}

.default-editor{
  padding-top: 10px;
  padding-bottom: 10px;
}
.editor-icon {
  line-height: 36px !important;
}

.editor-button {
  padding-left: 10px;
  padding-right: 10px;
}

.icon-sidebar {
  float: left;
  margin-right: 10px;
  margin-top: 26px !important;
  height: 10px !important;
  line-height: 10px !important;
}
.filled-col{
  line-height:40px;
}
.paddingbottom20{
  padding-bottom: 20px;
}
.marginright10 {
  margin-right: 10px;
}

.bc-container {
  padding-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
}

.closeicon-sidebar {
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  z-index: 2000;
}

.modalheaderetichetta{
  padding: 10px;
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
}
.modalheaderetichetta button{
  float: right;
}
.tabletextcolor td{
  padding: 0px !important;
}
.tabletextcolor{
}
.labeltextcolor{

  display: block;
  margin-right: 63px;
}
.transactiontextcodice label:not(.label-icon).active{
  transform: translateY(-46%) !important;
}
.margintop20 {
  margin-top: 20px;
}
.margintop28{
  margin-top: 28px;
}
.padding0{
  padding: 0px !important;
}

.save-input {
  color: white;
  margin-top: -12px !important;
}

#print-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.8); /* White background with opacity */
  z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
}

.editor {
  position: absolute;
  width: 300px;
  right: 0;
  z-index: 1100;
  padding: 0px 15px;
}

.show {
  display: block;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s; /* Firefox */
  -webkit-animation: fadein 0.5s; /* Safari and Chrome */
  -o-animation: fadein 0.5s; /* Opera */
}
@keyframes fadein {
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.hide {
  display: none;
}

.modal-footer {
  display: none;
}

.profile-editor {
  position: absolute;
  float: left;
  padding-left: 20px;
}

.admin-dashboard {
  position: relative;
  left: 30%;
  width: 50%;
}

.image-preview {
  max-width: 50px;
  max-height: 50px;
  width: 50px;
  height: 50px;
}

.paddingtop10 {
  padding-top: 10px;
}

.row-sliders {
  padding-bottom: 25px;
  padding-top: 10px;
}

.input-field-bcformat label {
  top: 0 !important;
}

.color-picker-label {
  margin-bottom: 10px;
}

.margintop10 {
  margin-top: 10px;
}

.input-xls {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-xls + label {
  cursor: pointer;
}

.valignbottom {
  vertical-align: bottom;
}

.margintop150{
  margin-top: 150px;
}

.footer-image-align{
  width: 60px;
  vertical-align: middle;
  margin-left: 10px;
}

.footer-label{
  vertical-align: text-bottom;
}

.icons-container {
  margin-top: 10px;
}

.zoom-manager {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-bottom: 43px;
  margin-left: -40px;
}

.button-zoom i {
  font-size: 19px;
  line-height: normal;
  margin-left: -5px;
}

.reset-zoom {
  margin-bottom: 6px;
}

.quarterwidth {
  width: 25%;
}

.margintop250 {
  margin-top: 280px;
}

.react-confirm-dialog-content {
  width: 400px !important;
  height: 140px !important;
  padding-top: 13px !important;
  text-align: center;
}
.react-confirm-dialog-bg {
  z-index: 2000 !important;
}

.admindash-preview-image {
  width: 100px;
  height: 150px;
}

.admindash-preview-labelimage {
    width: 25%;
    height: 25%;
    margin-top: 20px;
    border: 1px solid rgba(0,0,0,0.2);
}

.admindash-button-container {
}

.input-multiselection .col {
  float: left;
  margin-top: 5px;
  margin-right: 20px;
}

.overlayclosebutton {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.printed-models-stats {
  width: 50%;
}

.centro{
  margin: 0 auto;
}
.width400{
  width: 400px;
}
.width40{
  width: 40%;
}
.width80{
  width: 80%;
}
.textjustify{
  text-align: justify;
}
.font-size12{
  font-size: 12px;
}

.margintop72{
  margin-top: 72px;
}
.marginbottom0{
  margin-bottom: 0px !important;
}
.Marquee {
  overflow-x: hidden;
}
.MarqueeContent {
  display: inline-block;
}

.model-name {
  color: #9e9e9e;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  text-align: right;
  margin-top: 0px;
}

.marginright0 {
  margin-right: 0px !important;
}

.button-apply-all {
  min-width: 195px;
}

[type="checkbox"].filled-in:not(:checked) + label::after {
  background-color: #FFF;
}

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #4aa3df;
  background-color: #4aa3df;
  z-index: 0;
}

.sticker-creator {
  width: 82%;
  float: right;
}

.sticker-list {
  width: 82%;
  float: right;
}

.label-stats {
  width: 82%;
  float: right;
}

.news-manager {
  width: 82%;
  float: right;
}

.admin-menu a:hover {
  color: red;
}

.sticker-table-container {
  width: 50%;
}

.sticker-list-search {
  width: 50% !important;
}

.redcolor {
  background-color: red;
}

.table-button {
  display: block;
  margin: auto;
}

.news-input {
  margin-top: 10px;
  width: 50%;
}

#models-import {
    padding: 25px;
}

.testo-login {
    font-weight: bold;
}

/* REMOVE ME */
.colla-img {
    width: 70%;
}
/* ********* */

/* News marquee media queries */
@media only screen and (min-width: 992px) {
  .news-container {
    display: block;
    width: 460px;
  }
}
@media only screen and (min-width: 1070px) {
  .news-container {
    display: block;
    width: 540px;
  }
}
@media only screen and (min-width: 1200px) {
  .news-container {
    display: block;
    width: 650px;
  }
}
@media only screen and (min-width: 1400px) {
  .news-container {
    display: block;
    width: 870px;
  }
}
@media only screen and (min-width: 1520px) {
  .news-container {
    display: block;
    width: 990px;
  }
}
@media only screen and (min-width: 1650px) {
  .news-container {
    display: block;
    width: 1100px;
  }
}
@media only screen and (min-width: 1750px) {
  .news-container {
    display: block;
    width: 1220px;
  }
}
@media only screen and (min-width: 1900px) {
  .news-container {
    display: block;
    width: 1360px;
  }
}
/* End of news marquee media queries */
