.nav-wrapper {
  background-color: #2c3e4f;
}
td.selectable:hover {
  /*border: 1px solid #5283fb;*/
  background-color: rgba(74, 163, 223, 0.4);
}
td.selectable.editing:hover {
  /*border: 1px solid #4167c5;*/
  background-color: rgba(74, 163, 223, 0.8);
}
td.selectable.editing {
  /*border: 1px solid #5283fb;*/
  background-color: rgba(74, 163, 223, 0.4);
}
#register-button {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background-color: #2c3e4f !important;
}
.defaultcolor {
  background-color: #4aa3df !important;
}
.defaultcolor:active {
  background-color: #4aa3df !important;
}
.defaultcolor:hover {
  background-color: #408fc5 !important;
}
.actioncolor {
  background-color: #2c3e4f !important;
}
.actioncolor:active{
  background-color: #2c3e4f !important;
}
.actioncolor:hover {
  background-color: #405a73 !important;
}
.background-sidebar {
  background-color: #405a73 !important;
  padding-left: 15px;
  padding-right: 15px;
}
.header-sidebar {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #2c3e4f;
  color: white;
  height: 60px;
  line-height: 64px;
}
.bordable-default {
  border-bottom: 0.5px solid #2c3e4f !important;
}
.background-containerdefault {
  background-color: #405a73 !important;
}
.palette {
  border-bottom: 0.5px solid #2c3e4f !important;
  font-size: 33px;
  padding: 20px !important;
}
.sidebar-selected {
  background-color: #2c3e4f !important;
}
.save-box {
  width: 15%;
  height: 160px;
  position: absolute;
  right: 0;
  background-color: #405a73;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
}
.input-xls + label {
  margin-right: 20px;
  padding: 5px;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 17px;
  color: white;
  background-color: #405a73;
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.input-xls:focus + label,
.input-xls + label:hover {
  background-color: #2c3e4f;
}
.panel-heading{
  background-color: #2c3e4f !important;
  color: white;
}
.button-zoom {
  float: right;
  background-color: #405a73 !important;
  margin-bottom: 2px;
  height: 29px !important;
  width: 30px !important;
}
.react-confirm-dialog-button.confirm {
  margin-right: 5px;
  background-color: #4aa3df !important;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.react-confirm-dialog-button.cancel {
  background-color: #4aa3df !important;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.welcome-phrase {
  font-weight: 800;
  margin-left: 50px;
  margin-right: 50px;
  text-align: justify;
  font-size: 14px;
  margin-top: 0;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  color: #7ab9f9;
  border: 1px solid #c3e1ff;
}
.placeholder-text {
  margin-left: 12px;
  margin-top: 9px;
  margin-bottom: 0px !important;
  color: white;
}
.palette-icon {
  color: #fff;
  font-size: 22px;
}
.text-select {
  color: white;
}
.bordertable-gray {
  border-bottom: 1px solid #d0d0d0;
}
.filled-col label {
  color:#ffffff;
}
.modal-title {
  color: white;
  padding-left: 10px;
  font-size: 30px;
  display:inline;
  margin: 0px;
}
/* label focus color */
.input-field input[type=text]:focus + label {
  color: #d0d0d0;
}
/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #d0d0d0;
  box-shadow: 0 1px 0 0 #d0d0d0;
}
.dropdown-content li>span {
  color: #000 !important;
}
.dropdown-content li>a {
  color: #000 !important;
}
.save-close-icon {
  position: relative;
  cursor: pointer;
  float: right;
  color: #9e9e9e;
  font-size: medium;
}
.colorpicker-colorlabel {
  color: white;
  font-size: 20px;
}
.input-text-sidebar {
  padding-left: 0px !important;
  color: white;
}
.footer-app {
  position: fixed;
  bottom: 0;
  opacity: 0.8;
  left: 25%;
  color: gray;
  margin-bottom: 4px;
  margin-left: -66px;
  font-size: 13px;
  font-weight: 800;
}
.icon-span {
  fill: white;
  margin-right: 3px;
}
.bordo{
  border-bottom: 1px solid lightgray;
}
.bordo-destra{
  border-right: 1px solid lightgray;
}
.text-center{
  text-align: center;
}
.boxlogin{
  background-color: gainsboro;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ccc;
}
.testo-login{
  font-size: 13px;
  color: #888;
}
.footer-login{
  line-height: 15px;
  font-size: 12px;
  color: #777;
}
.MarqueeContent {
  color: white;
  font-family: Arial, monospace;
}
.width250{
  width: 250px;
}