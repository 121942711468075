@import url(https://fonts.googleapis.com/css?family=Pacifico);
@import url(https://fonts.googleapis.com/css?family=Rammetto+One);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css?family=Quicksand);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
@import url(https://fonts.googleapis.com/css?family=Acme);
@import url(https://fonts.googleapis.com/css?family=Orbitron);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: auto;
}

body.loading {
  visibility: hidden;
}
@media print {
  * {
    margin: 0 !important;
    padding: 0 !important;
  }
  .no-print { display: none; }
  .printable { display: inherit; }
}

@page {
  size: 210mm 297mm;
  margin: 0;
  padding: 0;
}

canvas {
  image-rendering: crisp-edges;
}

.App {
  text-align: left;
}

.brand-logo {
  margin-left: 10px;
}

.App-body {
  width: 100%;
}

#canvas-container {
  width: 50%;
  float: left;
  margin: auto;
}

.canvas-container {
  position: absolute !important;
}

#builder-container {
  margin-top: 0px;
  width: 50%;
  overflow: auto;
  text-align: right;
}
.palette-sidebar {
  cursor: pointer;
  width: 64px !important;
  position: absolute;
  z-index: 10;
}

#label-selector-div {
  width: 50%;
  float: right;
}

#label-selector {
  margin: 0px auto;
  margin-top: 10px;
}

#edit-all {
  margin-bottom: 10px;
  margin-left: 34px;
}
#edit-one {
  margin-bottom: 10px;
}
.inputs-paper{
  margin: 0px auto;
  margin-top: 15px;
  width: 60%;
}
.input-multiselection {
  min-width: 250px;
}

#text-controls {
}

#fabric-canvas {
  border: 1px solid rgb(183, 183, 183);
}

#grid-canvas {
  position: absolute;
}

#canvas-elements {
  margin-right: 40px;
  margin-top: 20px;
  margin-left: 250px;
}

.add-button {
  margin-right: 10px;
}
.remove-button {
  margin-right: 10px;
}
.copy-button {
  margin-right: 10px;
}
.paste-button {
  margin-right: 10px;
}

#new-model {
  margin-top: 10px;
}

#print-table {
  margin: 0;
  padding: 0;
  border-spacing: 0;
  border-collapse: collapse;
  top: 0;
  left: 0;
  width: 210mm;
  height: 297mm;
}

#print-cell {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
  text-align: center;
}

#print-margin {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
#print-margin-internal {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}

.canvas-img {
  vertical-align: bottom;
}

table.print-preview {
  border-spacing: 0;
  border-collapse: collapse;
  padding: 0;
}
td.margin {
  border-spacing: 0;
  padding: 0;
  text-align: center;
}
td.selectable {
  border-spacing: 0;
  padding: 0;
  text-align: center;
  cursor: pointer;
}

.photoshop-picker {
  margin-top: 40px;
}

[type="checkbox"]+label {
  padding-left: 23px;
  line-height: 20px;
}

.login-form {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.bad-login {
  margin-top: 55px;
}

.marginleft12 {
  margin-left: 12px;
}

.margintop0 {
  margin-top: 0px;
}

.placeholder-row {
  margin-bottom: 10px;
}

.no-margin-row {
  margin-bottom: 0px !important;
}

.centered-hor {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.centered {
  position: absolute;
  text-align: center;
  font-size: 25px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.register-form {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.good-register {
  margin-top: 70px;
}
.bad-register {
  margin-top: 70px;
}

.centered-preloader {
  position: absolute;
  left: 50%;
}
.logo {
  padding-top: 4px;
}

.default-editor{
  padding-top: 10px;
  padding-bottom: 10px;
}
.editor-icon {
  line-height: 36px !important;
}

.editor-button {
  padding-left: 10px;
  padding-right: 10px;
}

.icon-sidebar {
  float: left;
  margin-right: 10px;
  margin-top: 26px !important;
  height: 10px !important;
  line-height: 10px !important;
}
.filled-col{
  line-height:40px;
}
.paddingbottom20{
  padding-bottom: 20px;
}
.marginright10 {
  margin-right: 10px;
}

.bc-container {
  padding-top: 10px;
  position: relative;
  width: 100%;
  height: 100%;
  display: none;
}

.closeicon-sidebar {
  position: absolute;
  top: 6px;
  right: 14px;
  cursor: pointer;
  z-index: 2000;
}

.modalheaderetichetta{
  padding: 10px;
  margin-top: -24px;
  margin-left: -24px;
  margin-right: -24px;
}
.modalheaderetichetta button{
  float: right;
}
.tabletextcolor td{
  padding: 0px !important;
}
.tabletextcolor{
}
.labeltextcolor{

  display: block;
  margin-right: 63px;
}
.transactiontextcodice label:not(.label-icon).active{
  -webkit-transform: translateY(-46%) !important;
          transform: translateY(-46%) !important;
}
.margintop20 {
  margin-top: 20px;
}
.margintop28{
  margin-top: 28px;
}
.padding0{
  padding: 0px !important;
}

.save-input {
  color: white;
  margin-top: -12px !important;
}

#print-overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.8); /* White background with opacity */
  z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
}

.editor {
  position: absolute;
  width: 300px;
  right: 0;
  z-index: 1100;
  padding: 0px 15px;
}

.show {
  display: block;
  animation: fadein 0.5s;
  -moz-animation: fadein 0.5s; /* Firefox */
  -webkit-animation: fadein 0.5s; /* Safari and Chrome */
  -o-animation: fadein 0.5s; /* Opera */
}
@keyframes fadein {
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  0% {
    opacity:0;
  }
  60% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.hide {
  display: none;
}

.modal-footer {
  display: none;
}

.profile-editor {
  position: absolute;
  float: left;
  padding-left: 20px;
}

.admin-dashboard {
  position: relative;
  left: 30%;
  width: 50%;
}

.image-preview {
  max-width: 50px;
  max-height: 50px;
  width: 50px;
  height: 50px;
}

.paddingtop10 {
  padding-top: 10px;
}

.row-sliders {
  padding-bottom: 25px;
  padding-top: 10px;
}

.input-field-bcformat label {
  top: 0 !important;
}

.color-picker-label {
  margin-bottom: 10px;
}

.margintop10 {
  margin-top: 10px;
}

.input-xls {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-xls + label {
  cursor: pointer;
}

.valignbottom {
  vertical-align: bottom;
}

.margintop150{
  margin-top: 150px;
}

.footer-image-align{
  width: 60px;
  vertical-align: middle;
  margin-left: 10px;
}

.footer-label{
  vertical-align: text-bottom;
}

.icons-container {
  margin-top: 10px;
}

.zoom-manager {
  position: absolute;
  left: 50%;
  bottom: 0;
  margin-bottom: 43px;
  margin-left: -40px;
}

.button-zoom i {
  font-size: 19px;
  line-height: normal;
  margin-left: -5px;
}

.reset-zoom {
  margin-bottom: 6px;
}

.quarterwidth {
  width: 25%;
}

.margintop250 {
  margin-top: 280px;
}

.react-confirm-dialog-content {
  width: 400px !important;
  height: 140px !important;
  padding-top: 13px !important;
  text-align: center;
}
.react-confirm-dialog-bg {
  z-index: 2000 !important;
}

.admindash-preview-image {
  width: 100px;
  height: 150px;
}

.admindash-preview-labelimage {
    width: 25%;
    height: 25%;
    margin-top: 20px;
    border: 1px solid rgba(0,0,0,0.2);
}

.admindash-button-container {
}

.input-multiselection .col {
  float: left;
  margin-top: 5px;
  margin-right: 20px;
}

.overlayclosebutton {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

.printed-models-stats {
  width: 50%;
}

.centro{
  margin: 0 auto;
}
.width400{
  width: 400px;
}
.width40{
  width: 40%;
}
.width80{
  width: 80%;
}
.textjustify{
  text-align: justify;
}
.font-size12{
  font-size: 12px;
}

.margintop72{
  margin-top: 72px;
}
.marginbottom0{
  margin-bottom: 0px !important;
}
.Marquee {
  overflow-x: hidden;
}
.MarqueeContent {
  display: inline-block;
}

.model-name {
  color: #9e9e9e;
  font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
  text-align: right;
  margin-top: 0px;
}

.marginright0 {
  margin-right: 0px !important;
}

.button-apply-all {
  min-width: 195px;
}

[type="checkbox"].filled-in:not(:checked) + label::after {
  background-color: #FFF;
}

[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #4aa3df;
  background-color: #4aa3df;
  z-index: 0;
}

.sticker-creator {
  width: 82%;
  float: right;
}

.sticker-list {
  width: 82%;
  float: right;
}

.label-stats {
  width: 82%;
  float: right;
}

.news-manager {
  width: 82%;
  float: right;
}

.admin-menu a:hover {
  color: red;
}

.sticker-table-container {
  width: 50%;
}

.sticker-list-search {
  width: 50% !important;
}

.redcolor {
  background-color: red;
}

.table-button {
  display: block;
  margin: auto;
}

.news-input {
  margin-top: 10px;
  width: 50%;
}

#models-import {
    padding: 25px;
}

.testo-login {
    font-weight: bold;
}

/* REMOVE ME */
.colla-img {
    width: 70%;
}
/* ********* */

/* News marquee media queries */
@media only screen and (min-width: 992px) {
  .news-container {
    display: block;
    width: 460px;
  }
}
@media only screen and (min-width: 1070px) {
  .news-container {
    display: block;
    width: 540px;
  }
}
@media only screen and (min-width: 1200px) {
  .news-container {
    display: block;
    width: 650px;
  }
}
@media only screen and (min-width: 1400px) {
  .news-container {
    display: block;
    width: 870px;
  }
}
@media only screen and (min-width: 1520px) {
  .news-container {
    display: block;
    width: 990px;
  }
}
@media only screen and (min-width: 1650px) {
  .news-container {
    display: block;
    width: 1100px;
  }
}
@media only screen and (min-width: 1750px) {
  .news-container {
    display: block;
    width: 1220px;
  }
}
@media only screen and (min-width: 1900px) {
  .news-container {
    display: block;
    width: 1360px;
  }
}
/* End of news marquee media queries */

.joyride-beacon {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  height: 36px;
  position: relative;
  width: 36px;
  z-index: 1500; }
.joyride-beacon:active, .joyride-beacon:focus, .joyride-beacon:hover {
  outline: none; }
.joyride-beacon__inner {
  -webkit-animation: joyride-beacon-inner 1.2s infinite ease-in-out;
  animation: joyride-beacon-inner 1.2s infinite ease-in-out;
  background-color: #7ab9f9;
  border-radius: 50%;
  display: block;
  height: 50%;
  left: 50%;
  opacity: 0.7;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 50%; }
.joyride-beacon__outer {
  -webkit-animation: joyride-beacon-outer 1.2s infinite ease-in-out;
  animation: joyride-beacon-outer 1.2s infinite ease-in-out;
  background-color: rgba(44, 62, 79, 0.2);
  border: 2px solid #4aa3df;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: center;
  transform-origin: center;
  width: 100%; }

.joyride-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1500; }

.joyride-hole {
  border-radius: 4px;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5);
  position: absolute; }
.joyride-hole.safari {
  box-shadow: 0 0 999px 9999px rgba(0, 0, 0, 0.5), 0 0 15px rgba(0, 0, 0, 0.5); }

.joyride-tooltip {
  background-color: #fff;
  border-radius: 4px;
  color: #555;
  cursor: default;
  -webkit-filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(-1px -2px 3px rgba(0, 0, 0, 0.3)) drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.3));
  opacity: 0;
  padding: 20px;
  pointer-events: auto;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  width: 290px;
  z-index: 1510; }
.joyride-tooltip--animate {
  -webkit-animation: joyride-tooltip 0.4s forwards;
  animation: joyride-tooltip 0.4s forwards;
  -webkit-animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18);
  animation-timing-function: cubic-bezier(0, 1.05, 0.55, 1.18); }
.joyride-tooltip__triangle {
  background-repeat: no-repeat;
  position: absolute; }
.joyride-tooltip.bottom, .joyride-tooltip.bottom-left, .joyride-tooltip.bottom-right {
  margin-top: 18px; }
.joyride-tooltip.bottom .joyride-tooltip__triangle, .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.bottom-right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28180 8 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 18px;
  left: 50%;
  top: -16px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 36px; }
.joyride-tooltip.top, .joyride-tooltip.top-left, .joyride-tooltip.top-right {
  margin-bottom: 18px; }
.joyride-tooltip.top .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2236px%22%20height%3D%2218px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%280%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  bottom: -16px;
  height: 18px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 36px; }
.joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
  left: 3%;
  -webkit-transform: translateX(0);
  transform: translateX(0); }
@media screen and (min-width: 480px) {
  .joyride-tooltip.bottom-left .joyride-tooltip__triangle, .joyride-tooltip.top-left .joyride-tooltip__triangle {
    left: 2%; } }
.joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
  left: auto;
  right: 3%;
  -webkit-transform: translateX(0);
  transform: translateX(0); }
@media screen and (min-width: 480px) {
  .joyride-tooltip.bottom-right .joyride-tooltip__triangle, .joyride-tooltip.top-right .joyride-tooltip__triangle {
    right: 2%; } }
.joyride-tooltip.left {
  margin-right: 18px; }
.joyride-tooltip.left .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%28270 8 8%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 36px;
  right: -16px;
  width: 18px; }
.joyride-tooltip.right {
  margin-left: 18px; }
.joyride-tooltip.right .joyride-tooltip__triangle {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2218px%22%20height%3D%2236px%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpolygon%20points%3D%220%2C%200%208%2C%208%2016%2C0%22%20fill%3D%22%23fff%22%20transform%3D%22scale%282.25%29%20rotate%2890 4 4%29%22%3E%3C%2Fpolygon%3E%3C%2Fsvg%3E");
  height: 36px;
  left: -16px;
  width: 18px; }
.joyride-tooltip__close {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2212px%22%20height%3D%2212px%22%20viewBox%3D%220%200%2016%2016%22%20version%3D%221.1%22%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M14.117.323l-6.073%206.075-5.449-6.075c-.431-.431-1.131-.431-1.562%200-.431.431-.431%201.131%200%201.562l5.449%206.075-6.159%206.159c-.431.431-.431%201.134%200%201.562.431.431%201.131.431%201.562%200l6.075-6.073%205.449%206.073c.429.431%201.131.431%201.56%200%20.431-.429.431-1.131%200-1.562l-5.452-6.073%206.159-6.161c.431-.431.431-1.131%200-1.562-.431-.431-1.129-.431-1.56%200z%22%20fill%3D%22rgba(85, 85, 85, 0.5)%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  position: absolute;
  right: 10px;
  text-decoration: none;
  top: 10px;
  width: 12px;
  z-index: 10;
  display: block; }
.joyride-tooltip__close:active, .joyride-tooltip__close:focus, .joyride-tooltip__close:hover {
  outline: none; }
.joyride-tooltip__close:hover, .joyride-tooltip__close:focus {
  color: rgba(60, 60, 60, 0.5);
  outline: none; }
.joyride-tooltip__close--header {
  right: 20px;
  top: 20px; }
.joyride-tooltip__header {
  border-bottom: 1px solid #4aa3df;
  color: #555;
  font-size: 20px;
  padding-bottom: 6px;
  padding-right: 18px;
  position: relative; }
.joyride-tooltip__header ~ .joyride-tooltip__main {
  padding: 12px 0 18px; }
.joyride-tooltip__main {
  font-size: 16px;
  padding-bottom: 18px;
  padding-right: 18px; }
.joyride-tooltip__footer {
  text-align: right; }
.joyride-tooltip__button {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 0; }
.joyride-tooltip__button:active, .joyride-tooltip__button:focus, .joyride-tooltip__button:hover {
  outline: none; }
.joyride-tooltip__button--primary {
  background-color: #4aa3df;
  border-radius: 4px;
  color: #fff;
  padding: 6px 12px;
  transition: background-color 0.2s ease-in-out; }
.joyride-tooltip__button--primary:active, .joyride-tooltip__button--primary:focus, .joyride-tooltip__button--primary:hover {
  background-color: #7ab9f9;
  color: #fff; }
.joyride-tooltip__button--secondary {
  color: #4aa3df;
  margin-right: 10px; }
.joyride-tooltip__button--skip {
  color: #ccc;
  float: left;
  margin-right: 10px; }
.joyride-tooltip--standalone .joyride-tooltip__main {
  padding-bottom: 0; }
.joyride-tooltip--standalone .joyride-tooltip__footer {
  display: none; }
@media screen and (min-width: 480px) {
  .joyride-tooltip {
    width: 360px; } }
@media screen and (min-width: 960px) {
  .joyride-tooltip {
    width: 450px; } }

@-webkit-keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
    transform: perspective(1px) scale(1); } }

@keyframes joyride-tooltip {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(1px) scale(1);
    transform: perspective(1px) scale(1); } }

@-webkit-keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@keyframes joyride-beacon-inner {
  20% {
    opacity: 0.9; }
  90% {
    opacity: 0.7; } }

@-webkit-keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes joyride-beacon-outer {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  45% {
    opacity: 0.7;
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  100% {
    opacity: 0.9;
    -webkit-transform: scale(1);
    transform: scale(1); } }
.nav-wrapper {
  background-color: #2c3e4f;
}
td.selectable:hover {
  /*border: 1px solid #5283fb;*/
  background-color: rgba(74, 163, 223, 0.4);
}
td.selectable.editing:hover {
  /*border: 1px solid #4167c5;*/
  background-color: rgba(74, 163, 223, 0.8);
}
td.selectable.editing {
  /*border: 1px solid #5283fb;*/
  background-color: rgba(74, 163, 223, 0.4);
}
#register-button {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  background-color: #2c3e4f !important;
}
.defaultcolor {
  background-color: #4aa3df !important;
}
.defaultcolor:active {
  background-color: #4aa3df !important;
}
.defaultcolor:hover {
  background-color: #408fc5 !important;
}
.actioncolor {
  background-color: #2c3e4f !important;
}
.actioncolor:active{
  background-color: #2c3e4f !important;
}
.actioncolor:hover {
  background-color: #405a73 !important;
}
.background-sidebar {
  background-color: #405a73 !important;
  padding-left: 15px;
  padding-right: 15px;
}
.header-sidebar {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  border-bottom: 1px solid #2c3e4f;
  color: white;
  height: 60px;
  line-height: 64px;
}
.bordable-default {
  border-bottom: 0.5px solid #2c3e4f !important;
}
.background-containerdefault {
  background-color: #405a73 !important;
}
.palette {
  border-bottom: 0.5px solid #2c3e4f !important;
  font-size: 33px;
  padding: 20px !important;
}
.sidebar-selected {
  background-color: #2c3e4f !important;
}
.save-box {
  width: 15%;
  height: 160px;
  position: absolute;
  right: 0;
  background-color: #405a73;
  padding: 15px;
  border-radius: 0px 0px 15px 15px;
}
.input-xls + label {
  margin-right: 20px;
  padding: 5px;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 17px;
  color: white;
  background-color: #405a73;
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.input-xls:focus + label,
.input-xls + label:hover {
  background-color: #2c3e4f;
}
.panel-heading{
  background-color: #2c3e4f !important;
  color: white;
}
.button-zoom {
  float: right;
  background-color: #405a73 !important;
  margin-bottom: 2px;
  height: 29px !important;
  width: 30px !important;
}
.react-confirm-dialog-button.confirm {
  margin-right: 5px;
  background-color: #4aa3df !important;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.react-confirm-dialog-button.cancel {
  background-color: #4aa3df !important;
  border: none;
  border-radius: 2px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 2rem;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
  color: white;
  cursor: pointer;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
}
.welcome-phrase {
  font-weight: 800;
  margin-left: 50px;
  margin-right: 50px;
  text-align: justify;
  font-size: 14px;
  margin-top: 0;
  background-color: aliceblue;
  border-radius: 10px;
  padding: 10px;
  color: #7ab9f9;
  border: 1px solid #c3e1ff;
}
.placeholder-text {
  margin-left: 12px;
  margin-top: 9px;
  margin-bottom: 0px !important;
  color: white;
}
.palette-icon {
  color: #fff;
  font-size: 22px;
}
.text-select {
  color: white;
}
.bordertable-gray {
  border-bottom: 1px solid #d0d0d0;
}
.filled-col label {
  color:#ffffff;
}
.modal-title {
  color: white;
  padding-left: 10px;
  font-size: 30px;
  display:inline;
  margin: 0px;
}
/* label focus color */
.input-field input[type=text]:focus + label {
  color: #d0d0d0;
}
/* label underline focus color */
.input-field input[type=text]:focus {
  border-bottom: 1px solid #d0d0d0;
  box-shadow: 0 1px 0 0 #d0d0d0;
}
.dropdown-content li>span {
  color: #000 !important;
}
.dropdown-content li>a {
  color: #000 !important;
}
.save-close-icon {
  position: relative;
  cursor: pointer;
  float: right;
  color: #9e9e9e;
  font-size: medium;
}
.colorpicker-colorlabel {
  color: white;
  font-size: 20px;
}
.input-text-sidebar {
  padding-left: 0px !important;
  color: white;
}
.footer-app {
  position: fixed;
  bottom: 0;
  opacity: 0.8;
  left: 25%;
  color: gray;
  margin-bottom: 4px;
  margin-left: -66px;
  font-size: 13px;
  font-weight: 800;
}
.icon-span {
  fill: white;
  margin-right: 3px;
}
.bordo{
  border-bottom: 1px solid lightgray;
}
.bordo-destra{
  border-right: 1px solid lightgray;
}
.text-center{
  text-align: center;
}
.boxlogin{
  background-color: gainsboro;
  border-radius: 10px;
  padding: 20px;
  border: 1px solid #ccc;
}
.testo-login{
  font-size: 13px;
  color: #888;
}
.footer-login{
  line-height: 15px;
  font-size: 12px;
  color: #777;
}
.MarqueeContent {
  color: white;
  font-family: Arial, monospace;
}
.width250{
  width: 250px;
}
